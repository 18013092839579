import React, { useEffect } from "react";

import { AppLogic } from "../hooks/useAppLogic";
import oidc from "src/services/openIdConnect";
import routes from "../routes";
import useLoggedInRedirect from "../hooks/useLoggedInRedirect";

interface IndexProps {
  appLogic: AppLogic;
}

export const Index = (props: IndexProps) => {
  const { appLogic } = props;

  useLoggedInRedirect(
    appLogic.portalFlow,
    appLogic.portalFlow.getRouteFor("LOGGED_IN")
  );

  useEffect(() => {
    if (!oidc.isOIDCAuthenticated()) {
      appLogic.portalFlow.goTo(routes.auth.oAuthStart, {}, { redirect: true });
    }
  }, [appLogic.portalFlow]);

  return <React.Fragment></React.Fragment>;
};

export default Index;
